import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { isObject } from "lodash";
import { addModalErrorMessage } from "../pages/redux/circuitSlice";

type Callback = (error: Error, showInModal?: boolean) => void

export function useShowError(): Callback {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    return (error, showInModal) => {
        console.error(error);

        function isAxiosError(error: Error | AxiosError): error is AxiosError {
            return Boolean((error as AxiosError).response);
        }

        function showMessage(value: string) {
            if (showInModal) {
                dispatch(
                    addModalErrorMessage({
                        key: Math.random().toString(),
                        message: value
                    })
                );
            } else {
                enqueueSnackbar(value, { variant: 'error' });
            }
        }

        if (isAxiosError(error)) {
            if (error.response) {
                if (error.response.status === 400 || error.response.status === 404) {
                    if (isObject(error.response.data)) {
                        for (const [key, value] of Object.entries(error.response.data)) {
                            const keyText = key !== 'detail' ? t(`shared.${key}`) : null;
                            if (Array.isArray(value)) {
                                value.map(error => showMessage(
                                    keyText ?
                                        `${keyText} : ${error}` :
                                        error
                                ));
                            } else {
                                showMessage(
                                    keyText ?
                                        `${keyText} : ${value}` :
                                        value
                                );
                            }
                        }
                    } else {
                        showMessage(error.message);
                    }
                } else if (error.response.status === 401 || error.response.status === 403) {
                    showMessage(t('errors.permissions'));
                } else if (error.response.status === 409) {
                    showMessage(t('errors.conflict') + ': ' + error.response.data?.error);
                } else if (error.response.status === 500) {
                    showMessage(t('errors.servers'));
                }
            }
            console.log('error.config : ', error.config);
        } else {
            showMessage(error.message);
        }
    };
}
