import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { cloneDeep, isEqual, set } from "lodash";
import { useHotelsRooms } from "../hooks/hotelsRooms";
import { useGetCircuitProductsFromItineraryType } from "../utils/getCircuitProductsFromItineraryType";
import { Circuit } from "../../Itinerary/objects/circuit";
import { IataAirline } from "../../CartMaterial/objects/iataAirline";
import { Itinerary } from "../../Itinerary/objects/itinerary";
import { PricePeriod } from "../objects/pricePeriod";
import { PriceTerrestrial } from "../objects/priceTerrestrial";
import { PriceFlight } from "../objects/priceFlight";
import { IataAirport } from "../../Itinerary/objects/iataAirport";
import { TripVersion } from "../../Menu/MaterialTripList/objects/tripVersion";
import { HotelRooms } from "../objects/hotelRooms";
import { ManualProductFlightCart } from "../../Itinerary/objects/manualProductFlightCart";
import { AccommodationCart } from "../../Itinerary/objects/accommodationCart";
import { AdditionnalProduct } from "../hooks/hotelAdditionnalProducts";
import { ProductPrice } from "../../Itinerary/objects/productPrice";
import { Flight } from "../../Itinerary/network/flight";

export enum DestinationTab {
    ALL,
    PACKAGES,
    CUSTOM
}

export type TravelerErrorMessage = {
    travelerId: number,
    code: 'first-name-required' |
    'last-name-required' |
    'same-names' |
    'age-changed' |
    'phone-email-required' |
    'email-required' |
    'phone-required',
    message: string
}

type PrecartFlightPriceDetail = {
    number: 2,
    taxes: ProductPrice[],
    total_price: string
}

type State = {
    headerHeight: number,
    package: {
        data: Circuit | null,
        version: number | null,
        versions: TripVersion[],
        variant: number | null,
        itinerary: Itinerary[],
        periods: PricePeriod[] | null,
        priceTerrestrial: PriceTerrestrial[] | null,
        priceFlight: PriceFlight[] | null,
        pricesLoaded: boolean,
        vatAmount: number,
        flightBoParams: {
            allow_manual_flight: boolean,
            period: {
                start_date: string,
                end_date: string
            }[] | null
        }[] | null,
    },
    inputs: Pick<
        State['precart'],
        'departureCity' | 'startDate' | 'duration' | 'rooms' | 'transportsPreferences' | 'periodId'
    >,
    precart: {
        mode: 'creation' | 'edit',
        startDate: string | null,
        periodId: number | null,
        departureCity: IataAirport | null,
        rooms: {
            adultsCount: number,
            childrenCount: number,
            childrenAges: number[],
        }[],
        applicationFees: {
            price: number
        } | null,
        transportsPreferences: {
            flightClass: number,
            airlines: IataAirline[],
            onlyDirectFlights: boolean,
            isHoneyMoon: boolean,
            isWeddingAnniversary: boolean
        },
        room_price: ReturnType<typeof useHotelsRooms>['response'],
        duration: number | null,
        flight: {
            type: 'online',
            data: {
                id: number,
                flight_key: string,
                total_price: string,
                outbounds: Flight['outbounds'],
                last_ticketing_date: string | null,
                adult: PrecartFlightPriceDetail | null,
                baby: PrecartFlightPriceDetail | null,
                children: PrecartFlightPriceDetail | null
            }[]
        } | {
            type: 'offline',
            data: ManualProductFlightCart
        } | null,
        enabledOptionalProducts: ({
            type: 'normal' | 'manual',
            productType: number,
            id: number,
            prices: ProductPrice[]
        } | {
            type: 'additionnal-product',
            hotel?: AccommodationCart['hotel'][number],
            data: AdditionnalProduct
        })[],
        enabledOptionalProductsInputs: ({
            type: 'normal' | 'manual',
            productType: number,
            id: number,
            prices: ProductPrice[]
        } | {
            type: 'additionnal-product',
            hotel?: AccommodationCart['hotel'][number],
            data: AdditionnalProduct
        })[],
        selectedInsurance: ({
            type: 'normal' | 'manual',
            id: number,
            name: string,
            prices: ProductPrice[]
        } | {
            type: 'additionnal-product',
            products: AdditionnalProduct[]
        }) | null,
        selectedRooms: (
            Omit<HotelRooms['rooms'][number], 'variants'> & {
                variant: HotelRooms['rooms'][number]['variants'][number],
                hotel?: AccommodationCart['hotel'][number],
                accommodation?: AccommodationCart,
                provider?: {
                    id: number,
                    isCustom: boolean
                }
            }
        )[],
        products: Awaited<ReturnType<typeof useGetCircuitProductsFromItineraryType>>,
        passengersGroups: {
            groups: any[],
            withApplicant: boolean,
            tempContactLead: any | null,
            tempTravelers: any[],
            moreInfo: any[],
            infoIncomplete: any[]
        },
        nextRoom: number,
        travelersErrorMessages: TravelerErrorMessage[],
        unlockedSteps: { [step: number]: boolean },
        modalErrors: {
            key: string,
            message: string
        }[]
    },
    flightsSearchPage: {
        providersObj: any,
        searched_group: any,
        editSearch: boolean,
        loadingRequest: boolean,
        countProviders: number
    }
}

const initialState: State = {
    headerHeight: 0,
    package: {
        data: null,
        version: null,
        versions: [],
        variant: null,
        itinerary: [],
        periods: null,
        priceFlight: null,
        priceTerrestrial: null,
        pricesLoaded: false,
        vatAmount: 0,
        flightBoParams: null,
    },
    inputs: {
        departureCity: null,
        duration: null,
        periodId: null,
        rooms: [
            {
                adultsCount: 2,
                childrenCount: 0,
                childrenAges: []
            }
        ],
        startDate: null,
        transportsPreferences: {
            flightClass: 0,
            airlines: [],
            onlyDirectFlights: false,
            isHoneyMoon: false,
            isWeddingAnniversary: false
        },
    },
    precart: {
        mode: 'creation',
        startDate: null,
        periodId: null,
        departureCity: null,
        rooms: [
            {
                adultsCount: 2,
                childrenCount: 0,
                childrenAges: []
            }
        ],
        transportsPreferences: {
            flightClass: 0,
            airlines: [],
            onlyDirectFlights: false,
            isHoneyMoon: false,
            isWeddingAnniversary: false
        },
        room_price: [],
        duration: null,
        flight: null,
        enabledOptionalProducts: [],
        enabledOptionalProductsInputs: [],
        selectedInsurance: null,
        selectedRooms: [],
        products: {
            accommodations: [],
            cars: [],
            pois: [],
            transfers: [],
            flights: [],
            trains: [],
            cruises: [],
            ferries: [],
            assistances: [],
            insurances: [],
            manualProducts: []
        },
        passengersGroups: {
            groups: [],
            infoIncomplete: [],
            moreInfo: [],
            tempContactLead: null,
            tempTravelers: [],
            withApplicant: true
        },
        applicationFees: null,
        nextRoom: 0,
        travelersErrorMessages: [],
        unlockedSteps: {},
        modalErrors: []
    },
    flightsSearchPage: {
        providersObj: {},
        searched_group: null,
        editSearch: false,
        loadingRequest: false,
        countProviders: 0
    }
}

const slice = createSlice({
    name: 'circuit',
    initialState,
    reducers: {
        addModalErrorMessage(
            state,
            action: PayloadAction<State['precart']['modalErrors'][number]>
        ): void {
            state.precart.modalErrors.push(action.payload);
        },
        changeHeaderHeight(
            state,
            action: PayloadAction<number>
        ): void {
            state.headerHeight = action.payload;
        },
        changePrecartFlight(
            state,
            action: PayloadAction<any>
        ): void {
            if (state.precart.flight?.type === 'online') {
                state.precart.flight.data![6] = action.payload;
            }
        },
        deleteModalErrorMessage(
            state,
            action: PayloadAction<State['precart']['modalErrors'][number]['key']>
        ): void {
            const index = state.precart.modalErrors.findIndex((item) => {
                return item.key === action.payload;
            });
            state.precart.modalErrors.splice(index, 1);
        },
        deleteOptionalAdditionnalProduct(
            state: State,
            action: PayloadAction<AdditionnalProduct>
        ): void {
            state.precart.enabledOptionalProducts = state.precart.enabledOptionalProducts.filter((item) => {
                return item.type !== 'additionnal-product' ||
                       !isEqual(item.data, action.payload);
            });
        },
        resetCircuit: (state): void => {
            const newState = cloneDeep(initialState);
            const tempContactLead = state.precart.passengersGroups.tempContactLead;
            for (const key in newState) {
                set(state, key, newState[key as keyof typeof newState]!);
            }
            state.precart.passengersGroups.tempContactLead = tempContactLead;
        },
        setPackage<K extends keyof State['package']>(
            state: State,
            action: PayloadAction<{
                key: K,
                value: State['package'][K],
            }>
        ): void {
            state.package[action.payload.key] = action.payload.value;
        },
        setPreCart<K extends keyof State['precart']>(
            state: State,
            action: PayloadAction<{
                key: K,
                value: State['precart'][K],
            }>
        ): void {
            state.precart[action.payload.key] = action.payload.value;
        },
        setInputs<K extends keyof State['inputs']>(
            state: State,
            action: PayloadAction<{
                key: K,
                value: State['inputs'][K],
            }>
        ): void {
            state.inputs[action.payload.key] = action.payload.value;
        },
        setPassengersGroups<K extends keyof State['precart']['passengersGroups']>(
            state: State,
            action: PayloadAction<{
                key: K,
                value: State['precart']['passengersGroups'][K],
            }>
        ): void {
            state.precart.passengersGroups[action.payload.key] = action.payload.value;
        },
        setFlightsSearchPage<K extends keyof State['flightsSearchPage']>(
            state: State,
            action: PayloadAction<{
                key: K,
                value: State['flightsSearchPage'][K],
            }>
        ): void {
            state.flightsSearchPage[action.payload.key] = action.payload.value;
        },
        setUnlockedStep(
            state: State,
            action: PayloadAction<number>
        ): void {
            state.precart.unlockedSteps[action.payload] = true;
        }
    }
});

export const {
    addModalErrorMessage,
    changeHeaderHeight,
    changePrecartFlight,
    deleteModalErrorMessage,
    deleteOptionalAdditionnalProduct,
    resetCircuit,
    setUnlockedStep
} = slice.actions;

export default slice;

export const setPreCart = slice.actions.setPreCart as <
    K extends keyof State['precart'],
>(payload: {
    key: K,
    value: State['precart'][K],
}) => PayloadAction<{
    key: K,
    value: State['precart'][K],
}>;

export const setInputs = slice.actions.setInputs as <
    K extends keyof State['inputs'],
>(payload: {
    key: K,
    value: State['inputs'][K],
}) => PayloadAction<{
    key: K,
    value: State['inputs'][K],
}>;

export const setPackage = slice.actions.setPackage as <
    K extends keyof State['package'],
>(payload: {
    key: K,
    value: State['package'][K],
}) => PayloadAction<{
    key: K,
    value: State['package'][K],
}>;

export const setPassengersGroups = slice.actions.setPassengersGroups as <
    K extends keyof State['precart']['passengersGroups'],
>(payload: {
    key: K,
    value: State['precart']['passengersGroups'][K],
}) => PayloadAction<{
    key: K,
    value: State['precart']['passengersGroups'][K],
}>;

export const setFlightsSearchPage = slice.actions.setFlightsSearchPage as <
    K extends keyof State['flightsSearchPage'],
>(payload: {
    key: K,
    value: State['flightsSearchPage'][K],
}) => PayloadAction<{
    key: K,
    value: State['flightsSearchPage'][K],
}>;
